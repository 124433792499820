"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime-corejs2@7.17.8@@babel/runtime-corejs2/helpers/defineProperty.js"));

var _index = require("api/user/index");

var _auth = require("@/utils/auth");

var _router = require("@/router");

var _mutations;

// import router from '@/router'
var LOGIN = 'LOGIN'; // 获取用户信息

var SET_TOKEN = 'SET_TOKEN'; // 设置用户信息

var SET_USER = 'SET_USER'; // 设置用户信息

var LOGOUT = 'LOGOUT'; // 退出登录、清除用户数据

var USER_DATA = 'USER'; // 用户数据

var _default = {
  namespaced: true,
  state: {
    user: '',
    token: (0, _auth.getToken)()
  },
  mutations: (_mutations = {}, (0, _defineProperty2.default)(_mutations, SET_TOKEN, function (state, data) {
    state.token = data;
    (0, _auth.setToken)(data);
  }), (0, _defineProperty2.default)(_mutations, SET_USER, function (state, data) {
    state.user = data;
    localStorage.setItem(USER_DATA, JSON.stringify(data));
  }), (0, _defineProperty2.default)(_mutations, LOGOUT, function (state, data) {
    state.user = null;
    state.token = null;
    (0, _auth.removeToken)();
    localStorage.removeItem(USER_DATA);
  }), _mutations),
  actions: {
    login: function login(context, data) {
      return new Promise(function (resolve, reject) {
        (0, _index.login)(data.data).then(function (res) {
          context.commit(SET_USER, res.data.user);
          context.commit(SET_TOKEN, res.data.token);
          resolve(true);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    bind: function bind(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        (0, _index.bind)(data).then(function (res) {
          commit(SET_USER, res.data.user);
          commit(SET_TOKEN, res.data.token);
          resolve(true);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    disbindwechat: function disbindwechat(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        (0, _index.disbindwechat)().then(function (res) {
          commit(SET_USER, res.data.user);
          resolve(true);
        });
      });
    },
    // get user info
    getInfo: function getInfo(_ref3) {
      var commit = _ref3.commit,
          state = _ref3.state;
      return new Promise(function (resolve, reject) {
        (0, _index.getInfo)().then(function (res) {
          console.log('getInfo');
          console.log(res);
          var data = res.data;

          if (!data) {
            resolve(false);
          } else {
            console.log('getInfo');
            console.log(res.data); // 公众号跳转过来的token此刻需要设置一遍

            commit(SET_TOKEN, state.token);
            if (res.data.user) commit(SET_USER, res.data.user);
            resolve(res.data.user);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 修改资料
    profile: function profile(_ref4, data) {
      var commit = _ref4.commit,
          state = _ref4.state;
      return new Promise(function (resolve, reject) {
        (0, _index.userProfile)(data).then(function (res) {
          commit(SET_USER, res.data);
          resolve(res.data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 退出
    logout: function logout(context, payload) {
      return new Promise(function (resolve, reject) {
        (0, _index.userLogout)();
        context.commit(LOGOUT, payload);
        resolve(true);
      });
    }
  }
};
exports.default = _default;