"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;

var _settings = _interopRequireDefault(require("@/settings"));

var title = _settings.default.title || '易企达小程序专业提供商';

function getPageTitle(pageTitle) {
  if (pageTitle) {
    // return `${pageTitle}-${title}`
    return "".concat(title);
  }

  return "".concat(title);
}