"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("core-js/modules/es6.regexp.replace");

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _axios = _interopRequireDefault(require("axios"));

var _auth = require("@/utils/auth");

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _helpes = require("@/utils/helpes");

var _qs = _interopRequireDefault(require("qs"));

// 环境的切换
if (process.env.NODE_ENV == 'development') {
  //  axios.defaults.baseURL = '/api'
  _axios.default.defaults.baseURL = '/';
} else {
  // axios.defaults.baseURL = '/api'
  _axios.default.defaults.baseURL = '/';
} // 超时时间


_axios.default.defaults.timeout = 10000;
_axios.default.defaults.withCredentials = true; // request拦截器

_axios.default.interceptors.request.use(function (config) {
  config.headers['shopid'] = (0, _helpes.getUrlParam)('shopid');

  if ((0, _auth.getToken)()) {
    config.headers['token'] = (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug

  Promise.reject(error);
}); // respone拦截器


_axios.default.interceptors.response.use(function (response) {
  // 获取百度ACCESS_TOKEN
  if (response.config.url.includes('/oauth/2.0/token')) {
    return Promise.resolve(response.data);
  }

  var _response$data = response.data,
      code = _response$data.code,
      message = _response$data.message;

  if (code === 1001) {
    (0, _toast.default)(message);

    _vueRouter.default.replace({
      path: '/login'
    });

    return Promise.reject(message);
  } else if (code !== 0 && code !== 404) {
    console.log('到了这里BBBB');
    (0, _toast.default)(message);
    return Promise.reject(message); //return Promise.resolve(response.data)
  } else if (code === 404) {
    _vueRouter.default.push({
      path: '/404'
    });
  }

  return response.data;
}, function (error) {
  var _error$response$data = error.response.data,
      code = _error$response$data.code,
      message = _error$response$data.message;

  if (code === 404) {
    _vueRouter.default.push({
      path: '/404'
    });
  } else if (code === 401) {
    _vueRouter.default.push({
      path: '/401'
    });
  } else if (code === 413 || error.message.includes(413)) {
    (0, _toast.default)('文件太大');
  } else {
    _dialog.default.alert({
      title: '标题',
      message: JSON.stringify(error)
    }).then(function () {// on close
    });

    (0, _toast.default)({
      message: message ? code : '网络错误',
      duration: 1500,
      forbidClick: true
    });
  }

  return Promise.reject(error);
});

var _default = _axios.default;
exports.default = _default;