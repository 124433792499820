"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _helpes = require("@/utils/helpes");

var TokenKey = 'h5-vue-cli_token';

function getToken() {
  var urlToken = (0, _helpes.getUrlParam)('token');
  return _jsCookie.default.get(TokenKey) || urlToken;
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token, {
    expires: 7
  });
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}