"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userInfo = exports.toZhCurrency = exports.showNewBtn = exports.resizeImage = exports.mobileStar = exports.isWeixinBrowser = exports.getWxAuthorizeUrl = exports.getUrlParam = exports.getUrlKey = exports.getLoginUrl = exports.formatDate = exports.descartes = exports.delHtmlTag = exports.accSub = exports.accMul = exports.accDiv = exports.accAdd = exports.CompareKey = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.search");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.regexp.constructor");

var _store = _interopRequireDefault(require("@/store"));

/* import _this from '../main'

// 刷新用户信息至vuex和localStorage
export const refreshUserInfo = async () => {
  if (window.localStorage.getItem('six_foot_access_token')) {
    const data = await _this.$api.userInfo()
    _this.$store.commit('currentUserState', data.data)
  }
} */
var showNewBtn = function showNewBtn() {
  if (window.sessionStorage.getItem('new_btn')) {
    return true;
  }

  return false;
};

exports.showNewBtn = showNewBtn;

var getUrlKey = function getUrlKey(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var r = decodeURI(window.location.search).substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

exports.getUrlKey = getUrlKey;

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
} // formatDate(new Date(), 'yyyy-MM-dd')


var formatDate = function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }

  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };

  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }

  return JSON.parse(JSON.stringify(fmt));
}; // cookie方法


exports.formatDate = formatDate;

function setCookie(name, val, day) {
  var expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + day);
  document.cookie = "".concat(name, "=").concat(val, ";expires=").concat(expireDate.toGMTString());
}

;

function getCookies(cName) {
  if (document.cookie.length > 0) {
    var cStart = document.cookie.indexOf(cName + '=');

    if (cStart !== -1) {
      cStart = cStart + cName.length + 1;
      var cEnd = document.cookie.indexOf(';', cStart);
      if (cEnd === -1) cEnd = document.cookie.length;
      return unescape(document.cookie.substring(cStart, cEnd));
    }
  }

  return '';
}

function delCookie(name) {
  setCookie(name, '', -1);
}

var resizeImage = function resizeImage(url, weight, height) {
  return url + '?x-oss-process=image/resize,m_fill,w_' + weight + ',h_' + height;
};
/* export const CookieManager = {
  setCookie,
  getCookies,
  delCookie
} */
// cookie方法 end
// 清空storage和用户token的cookie

/*
export const emptyStorage = () => {
  window.sessionStorage.clear()
  window.localStorage.clear()
  delCookie('six_footer')
  delCookie('six_footer')
}
*/


exports.resizeImage = resizeImage;

var CompareKey = function CompareKey(key) {
  return function (obj1, obj2) {
    if (obj1[key] < obj2[key]) {
      return -1;
    } else if (obj1[key] === obj2[key]) {
      return 0;
    } else {
      return 1;
    }
  };
};

exports.CompareKey = CompareKey;

var descartes = function descartes(array) {
  if (array.length < 2) return array[0] || [];
  return [].reduce.call(array, function (col, set) {
    var res = [];
    col.forEach(function (c) {
      set.forEach(function (s) {
        var t = [].concat(Array.isArray(c) ? c : [c]);
        t.push(s);
        res.push(t);
      });
    });
    return res;
  });
};

exports.descartes = descartes;

var userInfo = function userInfo() {
  /* if (window.localStorage.getItem('six_foot_user_info') !== 'undefined') {
    let userInfo = JSON.parse(window.localStorage.getItem('six_foot_user_info'))
    if (userInfo) {
      return userInfo
    }
  }
  return {} */
  return _store.default.getters.user;
};

exports.userInfo = userInfo;

var toZhCurrency = function toZhCurrency(n) {
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
    return '数据非法';
  }

  var unit = '仟佰拾亿仟佰拾万仟佰拾圆角分';
  var str = '';
  n += '00';
  var p = n.indexOf('.');

  if (p >= 0) {
    n = n.substring(0, p) + n.substr(p + 1, 2);
  }

  unit = unit.substr(unit.length - n.length);

  for (var i = 0; i < n.length; i++) {
    str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
  }

  return str.replace(/零([仟佰拾角])/g, '零').replace(/(零)+/g, '零').replace(/零([万亿圆])/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^圆零?|零分/g, '').replace(/圆$/g, '圆整');
};

exports.toZhCurrency = toZhCurrency;

var accAdd = function accAdd(arg1, arg2) {
  var r1, r2, m;

  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }

  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }

  m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
};

exports.accAdd = accAdd;

var accSub = function accSub(arg1, arg2) {
  var r1, r2, m, n;

  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }

  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }

  m = Math.pow(10, Math.max(r1, r2)); // 动态控制精度长度

  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
};

exports.accSub = accSub;

var accMul = function accMul(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();

  try {
    m += s1.split('.')[1].length;
  } catch (e) {}

  try {
    m += s2.split('.')[1].length;
  } catch (e) {}

  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
};

exports.accMul = accMul;

var accDiv = function accDiv(arg1, arg2) {
  var t1 = 0;
  var t2 = 0;
  var r1;
  var r2;

  try {
    t1 = arg1.toString().split('.')[1].length;
  } catch (e) {}

  try {
    t2 = arg2.toString().split('.')[1].length;
  } catch (e) {}

  r1 = Number(arg1.toString().replace('.', ''));
  r2 = Number(arg2.toString().replace('.', ''));
  return r1 / r2 * Math.pow(10, t2 - t1);
};

exports.accDiv = accDiv;

var mobileStar = function mobileStar(value) {
  var start = value.slice(0, 3);
  var end = value.slice(-4);
  return "".concat(start, "****").concat(end);
}; // 判断是否微信


exports.mobileStar = mobileStar;

var isWeixinBrowser = function isWeixinBrowser() {
  var ua = window.navigator.userAgent.toLowerCase();

  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
};

exports.isWeixinBrowser = isWeixinBrowser;

var getLoginUrl = function getLoginUrl(path) {
  return "/login?redirect=".concat(window.location.protocol, "//").concat(window.location.host).concat(path);
};

exports.getLoginUrl = getLoginUrl;

var getWxAuthorizeUrl = function getWxAuthorizeUrl(path) {
  return "/authorize?redirect=".concat(window.location.protocol, "//").concat(window.location.host).concat(path);
};

exports.getWxAuthorizeUrl = getWxAuthorizeUrl;

var getUrlParam = function getUrlParam(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return '';
};

exports.getUrlParam = getUrlParam;

var delHtmlTag = function delHtmlTag(str) {
  return str.replace(/<[^>]+>/g, ''); // 正则去掉所有的html标记
};

exports.delHtmlTag = delHtmlTag;