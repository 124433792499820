"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.resetRouter = resetRouter;

var _typeof2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime-corejs2@7.17.8@@babel/runtime-corejs2/helpers/typeof.js"));

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.string.starts-with");

require("core-js/modules/web.dom.iterable");

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _Home = _interopRequireDefault(require("views/Home.vue"));

var _Index = _interopRequireDefault(require("views/Index.vue"));

var _Goods = _interopRequireDefault(require("views/Goods.vue"));

var _Prodetail = _interopRequireDefault(require("views/Prodetail"));

var _store = _interopRequireDefault(require("@/store"));

var _Fastcreatebeta = _interopRequireDefault(require("../views/Miniprogram/Wechat/Fastcreatebeta"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

_vue.default.use(_vueRouter.default);

var routes = [{
  path: '/home',
  name: 'home',
  component: _Home.default,
  meta: {
    title: '首页',
    keepAlive: false
  }
}, {
  path: '/',
  name: 'Index',
  component: _Index.default,
  meta: {
    title: '',
    keepAlive: false
  }
}, {
  path: '/goods/:id',
  name: 'Goods',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('views/GoodsDetail.vue'));
    });
  },
  meta: {
    title: '商品详情',
    keepAlive: false,
    auth: true
  }
}, {
  path: '/article/:id',
  name: 'ArticleDetail',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('views/Article/Detail.vue'));
    });
  },
  meta: {
    title: '',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/help/:id',
  name: 'helpDetail',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('views/Help/Detail.vue'));
    });
  },
  meta: {
    title: '',
    keepAlive: false,
    auth: false
  }
}, {
  path: '/prodetail',
  name: 'Prodetail',
  component: _Prodetail.default,
  meta: {
    title: '双录详情',
    keepAlive: false,
    auth: true
  }
}, {
  path: '/miniprogram/wechat/fastcreatebeta',
  name: 'MiniprogramFastcreatebeta',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/Miniprogram/Wechat/Fastcreatebeta'));
    });
  },
  meta: {
    title: '快速创建试用小程序',
    auth: false
  }
}, {
  path: '/404',
  name: '404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('views/404.vue'));
    });
  },
  meta: {
    title: '404',
    keepAlive: true
  }
}];

var routerContext = require.context('./', true, /\.js$/);

routerContext.keys().forEach(function (route) {
  // 如果是根目录的 index.js 、不处理
  if (route.startsWith('./index')) {
    return;
  }

  var routerModule = routerContext(route);
  /**
   * 兼容 import export 和 require module.export 两种规范
   */

  routes = routes.concat(routerModule.default || routerModule);
});
/*
routes = routes.concat({
  path: '*',
  redirect: '/404'
})
*/

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    base: process.env.BASE_URL,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: routes
  });
};

var myRouter = createRouter(); // const myRouter = new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

var history = window.sessionStorage;
history.clear();
var historyCount = history.getItem('count') * 1 || 0;
history.setItem('/', 0);
myRouter.beforeEach(function (to, from, next) {
  if (to.params.direction) {
    _store.default.commit('updateDirection', to.params.direction);
  } else {
    var toIndex = history.getItem(to.path);
    var fromIndex = history.getItem(from.path); // 判断并记录跳转页面是否访问过，以此判断跳转过渡方式

    if (toIndex) {
      if (!fromIndex || parseInt(toIndex, 10) > parseInt(fromIndex, 10) || toIndex === '0' && fromIndex === '0') {
        _store.default.commit('updateDirection', 'forward');
      } else {
        _store.default.commit('updateDirection', 'back');
      }
    } else {
      ++historyCount;
      history.setItem('count', historyCount);
      to.path !== '/' && history.setItem(to.path, historyCount);

      _store.default.commit('updateDirection', 'forward');
    }
  }

  next();
});

function resetRouter() {
  myRouter.replace('/login');
}

var _default = myRouter;
exports.default = _default;