"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.replace");

var _helpes = require("@/utils/helpes");

var _vue = _interopRequireDefault(require("vue"));

var _store = _interopRequireDefault(require("@/store"));

var _default = {
  data: function data() {
    return {};
  },
  methods: {
    wxLogin: function wxLogin(obj) {
      console.log(obj.$route); // this.getBaseUrl(obj)

      if ((0, _helpes.isWeixinBrowser)()) {
        window.location.replace(this.getBaseUrl(obj));
      }
    },
    getBaseUrl: function getBaseUrl(obj) {
      //    let redirect = obj.$route.query.redirect || '/'
      var redirect = "".concat(window.location.protocol, "//").concat(window.location.host) + (obj.$route.fullPath || '/');
      var uid = _store.default.getters.user && _store.default.getters.user.id ? _store.default.getters.user.id : '';

      if (uid) {
        redirect += redirect.includes('?') ? '&' : '?';
        redirect += "uid=".concat(uid);
      }

      console.log('跳转到微信的链接');
      console.log("".concat(process.env.VUE_APP_API_URL, "/oauth/baseinfo/wechat?redirect=") + encodeURIComponent(redirect));
      return "".concat(process.env.VUE_APP_API_URL, "/oauth/baseinfo/wechat?redirect=") + encodeURIComponent(redirect);
    },
    getAuthUrl: function getAuthUrl(obj) {
      //    let redirect = obj.$route.query.redirect || '/'
      var redirect = "".concat(window.location.protocol, "//").concat(window.location.host) + (obj.$route.fullPath || '/');
      var uid = _store.default.getters.user && _store.default.getters.user.id ? _store.default.getters.user.id : '';

      if (uid) {
        redirect += redirect.includes('?') ? '&' : '?';
        redirect += "uid=".concat(uid);
      }

      console.log('跳转到微信的链接');
      console.log("".concat(process.env.VUE_APP_API_URL, "/oauth/wechat?redirect=") + redirect);
      return "".concat(process.env.VUE_APP_API_URL, "/oauth/wechat?redirect=") + redirect;
    }
  }
};
exports.default = _default;